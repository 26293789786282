/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:49:09 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-22 14:20:28
 */
<template>
  <div class="teacherInformation">
    <div class="teacher_contop"></div>
    <div class="width_1200">
      <div class="teacherpic_box">
        <img :src="teacherInfo.HeadImg" alt />
        <div class="teacherinfo">
          <p>{{ teacherInfo.Name }}</p>
          <p>{{ teacherInfo.ShortTitle }}</p>
        </div>
      </div>
      <div class="introduce">
        <div class="title">讲师介绍</div>
        <div class="descript" v-html="teacherInfo.Descript"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      teacherInfo: {}
    };
  },
  created () {
  },
  mounted () {
    this.teacherInfo = JSON.parse(localStorage.getItem("teacherInfo"));
    // console.log(this.teacherInfo)
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.teacherInformation {
  .teacher_contop {
    height: 135px;
    z-index: 0;
    background: #76a6c8 url(../../assets/images/innertopbg.jpg) no-repeat 50% 0;
  }
  .teacherpic_box {
    display: flex;
    align-items: center;
    margin-top: -80px;
    img {
      border: 6px solid #fff;
      width: 143px;
      height: 143px;
      border-radius: 100%;
      display: block;
      overflow: hidden;
      text-align: center;
      margin-left: 40px;
      margin-right: 25px;
      background: #fff;
    }
    .teacherinfo {
      p {
        &:nth-child(1) {
          font-size: 24px;
          height: 24px;
          line-height: 24px;
          color: #fff;
        }
        &:nth-child(2) {
          font-size: 12px;
          margin-top: 40px;
          color: #999;
        }
      }
    }
  }
  .introduce {
    background: #fff;
    margin-top: 35px;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      color: #111;
      height: 50px;
      line-height: 50px;
      padding: 0px 20px;
      border-bottom: 1px solid #eee;
    }
    .descript {
      padding: 28px 20px;
      line-height: 28px;
      color: #555;
    }
  }
}
</style>